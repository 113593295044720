
/*********** Colors *****************/

.main_nav,
#title,
#header {
  background-color: #F7f7f7;
}
/*********** General styling *****************/

* {
  box-sizing: border-box;
  font-family: 'Raleway', sans-serif;
}

.main_nav,
#title {
  margin: 0;
}

.main_nav,
#title,
#profile,
.actress,
.year,
#acerca h1,
#copywright,
.title_acordeon_menu,
.sobre-foto h3,
.sobre-foto p,
.Galeria h1 {
  text-align: center;
}

a {
  text-decoration: none;
  color: #000;
}

a:visited {
  color: initial;
}

ul {
  list-style: none;
  padding: 0;
}

.margin_centered {
  margin-left: auto;
  margin-right: auto;
}

.is_hidden {
  display: none;
}

/*********** Footer *****************/

#copywright {
  padding: 25px 0;
}

#copywright p {
  margin: 0;
}

/*********** Title *****************/

#title {
  padding: 25px 0;
  font-size: 2rem;
}

/*********** Main Navigator Menu *****************/


#menu_bars {
  position: absolute;
  top: 22.5px;
  left: 20px;
  z-index: 1000000;
}

.main_nav {
  display: none;
  position: absolute;
  top: 87px;
  width: 100%;
  z-index: 10000;
}

.main_nav li {
  border-bottom: 1px solid #f2f5f7;
}

.link {
  display: block;
  padding: 10px 0;
}

.main_nav li:last-child {
  border-bottom: none;
}

.is_display {
  display: initial;
}

/*********** Jumbotron *****************/
#jumbotron img {
  width: 100%;
  height: 90vh;  
  top: 10%;
}

#jumbotron {
  height: 90vh;
  position: fixed;
}


.portada_md,
.portada_mediana,
.portada_lg {
  display: none;
}

#presentation_text {
  width: 90%;
  font-size: 5rem;
  position: absolute;
  top: 40%;
  left: 2.55%;
  z-index: 1;
}

#presentation_text p {
  margin: 10px 0;
  width: 70%;
  font-weight: 900
}

#presentation_text p:last-child {
  font-size: 3rem;
  width: 100%;
}

/*********** Perfil data *****************/

.actress,
.year {
  font-size: 1.25rem;
  font-weight: 700;
}

.characteristics {
  font-size: 1rem;
}

.characteristics li{
  margin: 5px 0;
}

.pass:last-child {
  margin-top: 20px;
}

/*********** Acerca Section *****************/

#acerca {
  padding: 20px;
  line-height: 1.5;
}

/*********** Portfolio gallery *****************/

.year {
  margin-bottom: 25px;
}

.photo_wrapper {
  margin-bottom: 20px;
}

.photo_wrapper,
.photo_wrapper img {
  width: 100%;
}

.galleryContainer img:last-child {
  margin-bottom: 0;
}

/*********** Demos Section *****************/

#demos {
  width: 90%;
  margin: 0 auto;
  padding-top: 70px;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
}

iframe {
  width: 90%;
  height: 250px;
  border: none;
}

/*********** Trayectoria Section *****************/

#trayectoria_container {
  padding: 25px 20px 0;
}

.title_acordeon_menu {
  font-size: 1.2rem;
  font-weight: 900;
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 25px 0;
  letter-spacing: 2px;
}

.trayectoria_type_container {
  margin-bottom: 25px;
}

.trayectoria_type_container span {
  margin-left: auto;
}

span.plus_symbol {
  font-size: 1.2rem;
}

.tab-group {
  margin: 0 auto;
  max-width: 40em;
  width: 100%
}

.tab {
  position: relative;
  width: 100%;
}

.tab input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -998;
}

.tab label {
  background-color: #fff;
  color: #000;
  display: block;
  font-weight: 700;
  line-height: 3;
  padding-left: .5em;
  transition: letter-spacing .55s;
}

.tab input:focus + label,
.tab label:hover {
  background-color: rgb(238, 237, 237);
  letter-spacing: 1px;
}

.tab-content {
  background-color: rgb(238, 237, 237);
  padding-left: 25px;
  max-height: 0;
  overflow: hidden;
  transition: all .35s;
}

.tab-content p {
  margin: 1em 0;
}

.tab input:checked ~ .tab-content {
  max-height: 12em;
}

/*********** Gallery of photos *****************/

.Galeria {
  padding-top: 20px;
}

.Galeria h1 {
  margin-bottom: 10px;
}

.photo-container {
  padding: 20px;
  padding-bottom: 0;
}

.foto {
  position: relative;
  padding: 0 0 1px;
  overflow: hidden;
}

.sobre-foto p {
  padding: 0 10px;
}

.sobre-foto {
  background: rgba(0,0,0, .6 );
  color: #FFF;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top:0;
  right:0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity .6s;
}

.sobre-foto:hover {
  opacity:1; 
}

.foto img {
 transform-origin: top left; 
  transition: transform .3s;
  width: 100%;
}

.foto:hover img{
  transform: scale(1.1);
}

.zoom-foto {
  display: inline-block;
  color: #000;
  padding: 15px 33px;
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
}

/*********** Contacto section *****************/

#img_wrapper {
  width: 200px;
  margin: 50px auto 0;
}

#contacto_img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

/*********** Contact Form *****************/

form {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

form input,
textarea {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  padding-left: 5px;
}

form input {
  height: 40px;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 1rem;
}

textarea::-moz-placeholder,
input::-moz-placeholder { /* Firefox 19+ */
  font-size: 1rem;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder { /* IE 10+ */
  font-size: 1rem;
}

textarea:-moz-placeholder,
input:-moz-placeholder { /* Firefox 18- */
  font-size: 1rem;
}

#submit {
  height: 50px;
  width: 125px;
  margin-top: 15px;
  font-size: 1rem;
  font-weight: 700;
  background-color: #fff;
  border: 2px solid rgb(133, 133, 133);
  color: rgb(133, 133, 133); 
  letter-spacing: 2px;
}

.validation_error {
  color: #ec5249;
  margin: 0 0 10px;
  padding-left: 15px;
  font-size: .9rem;
}

.validation_error_background {
  background-color: #ec5249;
  border: none;
  color: #fff;
}

.validation_error_background::placeholder {
  color: #fff;
}

/*********** Success and failed message after submitting contact form *****************/

#successMessage {
  border: 2px solid rgb(29, 167, 1);
  text-align: center;
  margin-top: 25px;
}

#successMessage h3 {
  color: rgb(29, 167, 1);
}


/*********** Social Media Menu*****************/

#social_media_container {
  position: fixed;
  top: 50%;
  display: none;
}

#social_media_container a {
  display: block;
  padding: 10px;
}

.social_icon {
  height: 30px;
}

#fb_icon path,
#mail_icon path,
#insta_icon path {
  fill: #fff;
}

#social_media_container div:nth-child(1) {
  background-color: #385998;
}

#social_media_container div:nth-child(2) {
  background-color: #d14836;
}

#social_media_container div:nth-child(3) {
  background-image: url(./img/insta_back.jpg);
  background-size: cover;
}

/*********** Social Media Menu*****************/

#not_found {
  padding-top: 70px;
  text-align: center;
  display: block;
  min-height: calc(100vh - 155px);
}

#not_found p:nth-child(1),
#not_found p:nth-child(2) {
  font-weight: 900;
  font-size: 1.3rem;
  letter-spacing: 2px;
}

#not_found p:nth-child(2) {
  margin-bottom: 50px;
}

#return_button {
  font-size: 1rem;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  background-color: #000;
  letter-spacing: 2px;
  border: none;
}

/*********** Media Queries *****************/

@media(min-width: 460px) {

  .portada_sm {
    display: none;
  }

  .portada_md {
    display: initial;
  }

}

@media(min-width: 650px) {

  .portada_md {
    display: none;
  }

  .portada_mediana {
    display: initial;
  }

}

@media(min-width: 768px) {

  /********* General *********/

  .sticky_footer {
    min-height: calc(100vh - 155px);
  }

  #header {
    display: flex;
    justify-content: space-between;
  }

  #title {
    font-size: 2.2rem;
    flex-basis: 30%;
  }
  
  .main_nav {
    align-items: center;
    display: initial;
    display: flex;
    position: initial;
    flex-basis: 70%;
    padding: 20px 0;
  }

  .main_nav li {
    border-right: 1px dotted #000;
    padding: 15px;
    margin: auto 0;
    font-size: 1.2rem;
  }

  .main_nav li:last-child {
    border-right: none
  }

  #menu_bars {
    display: none;
  }

  .link {
    padding: 0;
  }

  #presentation_text {
    top: 55%;
  }

  /*********** Portafolio *****************/
  
  .actress,
  .year {
  font-size: 1.5rem;
}

  .galleryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .galleryContainer p {
    flex-basis: 100%;
  }

  .photo_wrapper  {
    flex-basis: 45%;
  }

  #profile {
    margin-top: 53px;
  }

  /*********** Acerca *****************/

  #acerca {
    width: 90%;
    margin: 0 auto;
  }

  /*********** Demos *****************/

  #demos {
    grid-template-columns: 1fr 1fr;
  }


  /*********** Trayectoria acordeon menus *****************/

  .trayectoria_type_container {
    width: 70%;
    margin: 0 auto;
  }

  /*********** Fotos Gallery *****************/

  .Galeria {
    padding-top: 30px;
    width: 90%;
    margin: 0 auto;
  }

  .gallery_container {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  /*********** Form *****************/

  #contacto_container {
    display: flex;
  }

  #contacto_container form {
    flex-basis: 45%;
    margin-right: 0;
  }

  #img_wrapper {
    flex-basis: 20%;
  }

  /*********** Social Media *****************/

  #social_media_container {
    display: initial;
  }

}

@media(min-width: 1024px) {

  #title,
  .main_nav {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #title {
    padding-top: 22.5px;
  }

  .portada_mediana {
    display: none;
  }

  .portada_lg {
    display: initial;
  }

  .main_nav li {
    font-size: 1.35rem;
  }

  #presentation_text {
    margin-left: 5%;
  }

  #presentation_text p {
    font-size: 6rem;
  }
  
  #presentation_text p:last-child {
    font-size: 3.5rem;
  }

/*********** Portfolio gallery *****************/

  .actress,
  .year {
    font-size: 1.75rem;
  }

  .year {
    padding-top: 50px;
    margin: 0;
    margin-bottom: -25px,
  }

  .container {
    display: flex;
    justify-content: space-evenly;
  }

  #profile {
    flex-basis: 22.5%;
    order: 2;
    margin-top: 30px;
  }

  .galleryContainer {
    flex-basis: 72.5%;
    order: 1;
  }

  .photo_wrapper  {
    flex-basis: 22%;
  }

  /*********** Demos *****************/


  /*********** Trayectoria acordeon menus *****************/

  #trayectoria_container {
    display: flex;
    justify-content: space-around;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 70px;
  }

  .trayectoria_type_container {
    flex-basis: 30%;
  }

  /*********** Galery of photos *****************/

  .gallery_container {
    grid-template-columns: 33% 33% 33%;
  }

  /*********** Form *****************/

  #contacto_container form {
    flex-basis: 40%;
    padding-top: 70px;
  }

  #img_wrapper {
    padding-top: 90px;
  }

}